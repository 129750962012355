<template>
  <v-row class="justify-sm-space-around text-center mt-4">
    <v-col cols="auto">
      <v-btn :disabled="!$userHasScope('admin')" @click="locationReplace('/admin')"
        >Administración</v-btn
      >
    </v-col>
    <v-col cols="auto">
      <v-btn :disabled="!$userHasScope('trainer')" @click="locationReplace('/trainer')"
        >Formaciones</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    locationReplace(path) {
      window.location.replace(path);
    },
  },
};
</script>

<style scoped></style>
